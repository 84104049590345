#page-title{
    height: 320px;
    position: relative;
    @include flex();
    align-items: center;
    justify-content: center;
    overflow: hidden;
	@media screen and (max-width:$responsive){
		height: 140px;
		padding: 0;
		justify-content: center;
	}
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(#000,.16);
        z-index: 2;
    }
    .bg{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover;
		@include transition(1400ms,$ease:linear);
    }
    .box{
        color: rgba(#FFF,.96);
		position: relative;
		z-index: 3;
		white-space: nowrap;
		text-align: center;
        line-height: 1;
		transform: translateY(-8px);
		@media screen and (max-width:$responsive){
			text-align: center;
			transform: translateY(-4px);
		}
        #eng-title{
            content: attr(data-eng);
            font-size: 54px;
			font-weight: 700;
            display: block;
            margin: 0 0 6px;
			padding: 32px 0 0;
			letter-spacing: .08em;
			@media screen and (max-width:$responsive){
				font-size: 24px;
				margin: 0;
                padding: 6px 0 0;
			}
        }
        h1,p{
            font-size: 20px;
            font-weight: 700;
            transform: translateY(10px);
            @include transition(800ms,$delay:600ms);
			@media screen and (max-width:$responsive){
				font-size: 13px;
			}
        }
    }
}

#bread{
    padding: 20px 0;
    overflow: hidden;
    font-size: 13px;
	background: $eng;
	@media screen and (max-width:$responsive){
		padding: 16px 0;
		font-size: 11px;
		width: 100%;
		overflow: hidden;
		position: relative;
		&:after{
			content: '';
            background: -moz-linear-gradient(-45deg,  rgba($eng,0) 0%, rgba($eng,1) 75%);
            background: -webkit-linear-gradient(-45deg,  rgba($eng,0) 0%,rgba($eng,1)75%);
            background: linear-gradient(135deg,  rgba($eng,0) 0%,rgba($eng,1) 75%);
			width: 20px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
		}
		ol{
			width: 200%;
		}
	}
    a{
        color: #FFF;
		@media screen and (max-width:$responsive){
			white-space: nowrap;
		}
    }
    i{
        display: inline-block;
        margin: 0 12px 0 0;
        font-size: 11px;
        vertical-align: middle;
        transform: translateY(-1px);
		color: #FFF;
    }
    li{
        float: left;
        list-style: none;
        &:after{
            content: "\f054";
            font-family: "Font Awesome 5 Free";
            font-size: 8px;
            font-weight: 900;
            color: #FFF;
            display: inline-block;
            margin: 0 16px;
            transform: translateY(-2px);
        }
        &:last-child{
            &:after{
                display: none;
            }
        }
    }
}

