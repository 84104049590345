.site-company{
    .shopbox{
        margin: 0 0 64px;
        &:last-of-type{
            margin: 0;
        }
        &__inner{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;   
            }
            figure{
                width: 470px;
                @media screen and (max-width:$width){
                    width: 280px;   
                }
                @media screen and (max-width:$responsive){
                    width: auto;   
                }
                img{
                    width: 100%;
                }
            }
            &__data{
                width: calc(100% - 470px);
                padding: 0 0 0 48px;
                @media screen and (max-width:$width){
                    width: calc(100% - 280px);
                    padding: 0 0 0 32px;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 16px 0 0;
                    padding: 0;
                }
                &__meta{
                    margin: 0 0 16px;
                    position: relative;
                    dt{
                        font-size: 24px;
                        font-weight: 700;
                    }
                    dd{
                        font-size: 16px;
                    }
                    &__map{
                        position: absolute;
                        bottom: 4px;
                        right: 0;
                        @media screen and (max-width:$width){
                            position: relative;
                            right: auto;
                            bottom: auto;
                            margin: 16px 0 0;
                        }
                        a{
                            background: $eng;
                            border: $eng 1px solid;
                            color: #FFF;
                            font-size: 13px;
                            padding: 6px 16px;
                            border-radius: 999px;
                            i{
                                display: inline-block;
                                margin: 0 0 0 8px;
                                font-size: 11px;
                            }
                            &:hover{
                                background: #FFF;
                                color: $eng;
                            }
                        }
                    }
                }
                .table-style{
                    tbody{
                        tr{
                            th,td{
                                font-size: 16px;
                                padding: 16px 0;
                                @media screen and (max-width:$responsive){
                                    font-size: 14px;
                                    padding: 12px 0;
                                }
                                a{
                                    font-weight: 700;
                                    text-decoration: underline;
                                    i{
                                        transform: rotateY(180deg);
                                        display: inline-block;
                                        margin: 0 8px 0 0;
                                    }
                                }
                            }
                            th{
                                width: 160px;
                                @media screen and (max-width:$responsive){
                                    width: auto;
                                    padding-left: 15px;
                                    padding-right: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .company-table{
        background: #FFF;
        padding: 0 48px;
        box-shadow: 0 0 32px rgba(#000,.06);
        @include flex();
        @media screen and (max-width:$responsive){
            display: block;
            padding: 0;
        }
        .list{
            li{
                list-style: inside decimal;
            }
        }
        .table-style{
            width: calc(50% - 24px);
            font-size: 15px;
            @media screen and (max-width:$responsive){
                width: auto;   
            }
            tr{
                th{
                    width: 100px;
                    @media screen and (max-width:$responsive){
                        width: auto;   
                    }
                }
                @media screen and (max-width:$responsive){
                    td{
                        padding-left: 15px;
                        padding-right: 15px;
                    }   
                }
            }
        }
    }
}