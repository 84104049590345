.site-sitemap{
    .sitenav{
        li{
            list-style: none;
            margin: 0 0 56px;
            @media screen and (max-width:$width){
                margin: 0 0 32px;   
            }
            &:last-child{
                margin: 0;
            }
            dl{
                @include flex();
                justify-content: flex-start;
                @media screen and (max-width:$responsive){
                    display: block;   
                }
                dt{
                    font-size: 20px;
                    font-weight: 700;
                    width: 100%;
                    border-bottom: $border 1px solid;
                    padding: 0 0 12px;
                    padding-left: 16px;
                    margin: 0 0 16px;
                    position: relative;
                    @media screen and (max-width:$responsive){
                        font-size: 18px;   
                    }
                    &:before{
                        content: '';
                        width: 5px;
                        height: 26px;
                        position: absolute;
                        top: 5px;
                        left: 0;
                        background: $eng;
                        border-radius: 4px;
                    }
                }
                dd{
                    width: 18.4%;
                    margin: 0 2% 0 0;
                    @media screen and (max-width:$responsive){
                        width: auto;
                        margin: 0 0 10px;
                    }
                    &:nth-of-type(5n+5){
                        margin-right: 0;
                    }
                    a{
                        display: block;
                        background: $glay;
                        text-align: center;
                        padding: 15px 0;
                        font-size: 15px;
                        font-weight: 700;
                        color: $normal;
                        @media screen and (max-width:$width){
                            font-size: 14px;   
                        }
                        @media screen and (max-width:$responsive){
                            text-align: left;
                            padding: 10px 15px;
                        }
                        &:hover{
                            background: $eng;
                            color: #FFF;
                        }
                    }
                }
            }
        }
    }
}