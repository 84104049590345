#site-sidebar{
    position: sticky;
    top: 24px;
    @media screen and (max-width:$width){
        position: relative;
        top: 0;
    }
    .archive{
        &.mb-px{
            margin: 0 0 32px;
        }
        li{
            list-style: none;
            border-bottom: $border 1px solid;
            &:last-child{
                border: none;
            }
            a{
                padding: 12px 2px;
                padding-right: 30px;
                display: block;
                font-size: 16px;
                color: $normal;
                position: relative;
                &:hover{
                    color: $eng;
                    font-weight: 700;
                    &:after{
                        right: 10px;
                    }
                }
                &:after{
                    content: "\f061";
                    font-weight: 900;
                    font-family: "Font Awesome 5 Free";
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    color: $eng;
                    font-size: 12px;
                    @include transition(120ms, $ease:linear);
                }
            }
        }
    }
    
    // CaseSingle
    .cases{
        border: $border 1px solid;
        padding: 16px;
        margin: 0 0 32px;
        &__title{
            background: $eng;
            color: #FFF;
            font-weight: 700;
            font-size: 17px;
            padding: 4px 15px;
            border-radius: 2px;
            i{
                display: inline-block;
                margin: 0 8px 0 0;
            }
        }
        &__box{
            margin: 0 0 24px;
            &:last-of-type{
                margin: 0;
            }
            dl{
                @include flex();
                margin: 12px 0 0;
                dt{
                    width: 88px;
                    text-align: center;
                    background: darken($glay,2%);
                    padding: 2px 0;
                    font-size: 13px;
                    font-weight: 700;
                    @include flex();
                    align-items: center;
                    justify-content: center;
                    border-radius: 2px;
                }
                dd{
                    width: calc(100% - 88px);
                    padding: 0 0 0 16px;
                    font-size: 15px;
                    line-height: 1.66;
                }
            }
        }
    }
}