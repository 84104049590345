    $enji: #9b003f;
    .detailcontent{
        margin: 0 0 32px;
        font-size: 18px;
        line-height: 2;
        @media screen and (max-width:$responsive){
            font-size: 14px;   
        }
        figure.main{
            margin: 0 0 40px;
            @media screen and (max-width:$responsive){
                margin: 0 0 15px;   
            }
            img{
                width: 100%;
                height: auto;
            }
        }
        
        p{
            margin: 0 0 24px;
            @media screen and (max-width:$responsive){
                margin: 0 0 15px;   
            }
            &:last-of-type{
                margin: 0;
            }
        }
        img{
            max-width: 100%;
            height: auto;
        }
        iframe{
            width: 100%;
            height: 450px;
            vertical-align: bottom;
            @media screen and (max-width:$responsive){
                height: 200px;   
            }
        }
        a{
            text-decoration: underline;
            color: $eng;
            font-weight: 700;
        }
        blockquote{
            background: $glay;
            padding: 15px;
            padding-left: 82px;
            position: relative;
            margin: 0 0 24px;
            border-radius: 0 6px 6px 0;
            border-left: $border 4px solid;
            @media screen and (max-width:$responsive){
                padding-left: 64px;   
            }
            &:before{
                content: '';
                background: url("../../../images/common/blockquote.png") no-repeat;
                width: 45px;
                height: 37px;
                background-size: 45px;
                position: absolute;
                top: 15px;
                left: 15px;
                @media screen and (max-width:$responsive){
                    background: url("../../../images/common/blockquote.png") no-repeat;
                    background-size: 32px;
                    width: 32px;
                    height: 26px;
                }
            }
        }
        h2{
            background: $enji;
            color: #FFF;
            font-size: 26px;
            font-weight: 700;
            padding: 15px 24px;
            margin: 0 0 20px;
            border-radius: 4px;
            @media screen and (max-width:$responsive){
                font-size: 18px;   
            }
        }
        h3{
            margin: 0 0 24px;
            padding: 10px 0 12px;
            border-top: 3px solid $enji;
            border-bottom: 1px solid $enji;
            border-left: 0;
            font-size: 24px;
            line-height: 33px;
            font-weight: 700;
            @media screen and (max-width:$responsive){
                margin: 0 0 16px;
                font-size: 18px;   
            }
        }
        h4{
            margin: 0 0 16px;
            padding-left: 12px;
            font-size: 20px;
            border-left: 3px solid $enji;
            font-weight: bold;
            @media screen and (max-width:$responsive){
                font-size: 18px;
                margin: 0 0 12px;
            }
        }
        h5{
            margin: 0 0 12px;
            padding: 0 0 0 16px;
            font-size: 18px;
            position: relative;
            font-weight: bold;
            &:before{
                content: '';
                background: $enji;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
            @media screen and (max-width:$responsive){
                font-size: 16px;   
            }
        }
        
        .attention.a-3{
            border: 0 solid #727272;
            padding: 0;
            .attention-title{
                font-weight: bold;
                color: #fff;
                text-align: center;
                margin-bottom: 10px;
                background: #9b003f;
                padding: 11px 20px;
                margin: 0;
                position: relative;
                line-height: 1.5em;
            }
            ul{
                padding: 20px;
                border: 1px solid #c0c0c0;
                border-top: 0 solid;
                li{
                    list-style: disc inside;
                }
            }
        }
        
        table{
            border: $border 1px solid;
            border-collapse: collapse;
            width: 100%;
            max-width: 100%;
            margin: 0 0 24px;
            tbody{
                tr{
                    th,td{
                        border: $border 1px solid;
                        padding: 8px 15px;
                        width: auto !important;
                    }
                    th{
                        background: $glay;
                    }
                }
            }
        }
        
        ul,ol{
            margin: 0 0 24px;
            li{
                list-style: inside;
            }
        }
        
        .aligncenter{
            display: block;
            margin: 5px auto;
        }
        
        .link-button-green,
        .link-button-orange{
            margin: 0 0 24px;
            a{
                text-align: center;
                padding: 12px 0;
                display: block;
                text-decoration:none;
                color: #FFF;
                border-radius: 6px;
                &:hover{
                    opacity: .8;
                }
            }
        }
        .link-button-green a{
            background: #67ac01;
        }
        .link-button-orange a{
            background: #ff7d01;
        }
    }