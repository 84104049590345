.site-message{
    .messagebox{
        position: relative;
        padding: 72px 0;
        margin: 0 0 72px;
        @media screen and (max-width:$responsive){
            padding: 15px;
            margin: 0 0 24px;
        }
        &:before{
            content: '';
            background: $glay;
            position: absolute;
            top: 0;
            right: 0;
            width: calc(40% - 40px);
            height: 100%;
            @media screen and (max-width:$responsive){
                display: none;
            }
        }
        &.rvs{
            &:before{
                right: auto;
                left: 0;
            }
            .bg{
                left: auto;
                right: 0;
            }
        }
        &:last-of-type{
            margin: 0;
        }
        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 60%;
            height: 100%;
            overflow: hidden;
            @media screen and (max-width:$responsive){
                position: relative;
                width: 100%;
                height: 180px;
            }
            &__item{
                position: absolute;
                top: -10%;
                left: -10%;
                width: 120%;
                height: 120%;
                background-position: center;
                background-size: cover;
            }
        }
        &__inner{
            @include flex();
            justify-content: flex-end;
            position: relative;
            z-index: 2;
            @media screen and (max-width:$responsive){
                display: block;
                padding: 24px 0 0;
            }
            &.left{
                justify-content: flex-start;
            }
            &__mes{
                width: 50%;
                min-width: 650px;
                background: #FFF;
                padding: 48px;
                @include min();
                @media screen and (max-width:$responsive){
                    min-width: 1px;
                    width: auto;
                    padding: 0;
                }
                dt{
                    font-size: 30px;
                    font-weight: 700;
                    line-height: 1.56;
                    margin: 0 0 20px;
                    white-space: nowrap;
                    @media screen and (max-width:$responsive){
                        font-size: 20px;
                        white-space: normal;
                        margin: 0 0 15px;
                    }
                    strong{
                        color: $eng;
                    }
                }
                dd{
                    text-align: justify;
                    font-size: 16px;
                    margin: 0 0 20px;
                    @media screen and (max-width:$responsive){
                        font-size: 13px;
                        margin: 0;
                    }
                    &:last-of-type{
                        margin: 0;
                    }
                    &.name{
                        @include flex();
                        justify-content: flex-end;
                        transform: translateY(16px);
                        figure{
                            font-size: 12px;
                            @media screen and (max-width:$responsive){
                                font-size: 11px;   
                            }
                            img{
                                width: 200px;
                                @media screen and (max-width:$responsive){
                                    width: 150px;   
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .profile{
        @include flex();
        position: relative;
        align-items: center;
        border: $glay 8px solid;
        padding: 32px;
        @media screen and (max-width:$width){
            padding: 15px;
            border: none;
            display: block;
        }
        @media screen and (max-width:$responsive){
            padding: 0;   
        }
        figure{
            position: relative;
            z-index: 2;
            width: 500px;
            @media screen and (max-width:$width){
                width: 280px;
            }
            @media screen and (max-width:$responsive){
                width: auto;
                margin: 0 0 24px;
            }
            img{
                width: 100%;
            }
        }
        dl.meta{
            width: calc(100% - 500px);
            padding: 0 0 0 48px;
            @media screen and (max-width:$width){
                width: calc(100% - 280px);
                padding: 0 0 0 32px;
            }
            @media screen and (max-width:$responsive){
                width: auto;
                padding: 0;   
            }
            dt{
                @include min();
                font-size: 16px;
                strong{
                    line-height: 1.3;
                    font-size: 36px;
                    @media screen and (max-width:$responsive){
                        font-size: 24px;   
                    }
                }
            }
            dd{
                margin: 16px 0 0;
                font-size: 17px;
                @media screen and (max-width:$width){
                    font-size: 14px;   
                }
                &:last-of-type{
                    margin: 0;
                }
            }
        }
    }
    .profilelist{
        width: 100%;
        margin: 32px 0 0;
        @include flex();
        @media screen and (max-width:$responsive){
            display: block;
            margin: 24px 0 0;
        }
        li{
            width: 31%;
            list-style: none;
            @media screen and (max-width:$width){
                width: 32%;   
            }
            @media screen and (max-width:$responsive){
                width: auto;
                margin: 0 0 24px;
                &:last-child{
                    margin: 0;
                }
            }
            dl{
                dt{
                    font-size: 20px;
                    font-weight: 700;
                    border-bottom: $border 1px solid;
                    padding: 4px 0;
                    padding-left: 24px;
                    margin: 0 0 8px;
                    position: relative;
                    @media screen and (max-width:$width){
                        font-size: 16px;   
                    }
                    &:before{
                        content: '';
                        width: 16px;
                        height: 16px;
                        border: $eng 4px solid;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }
                }
                dd{
                    font-size: 17px;
                    @media screen and (max-width:$width){
                        font-size: 14px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 13px;   
                    }
                }
            }
        }
    }
}