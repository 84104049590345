.site-service{
    .profile{
        border: $glay 8px solid;
        padding: 24px;
        @include flex();
        align-items: center;
        @media screen and (max-width:$width){
            padding: 15px;
        }
        @media screen and (max-width:$responsive){
            display: block;
        }
        &__img{
            width: 260px;
            img{
                width: 100%;
            }
            @media screen and (max-width:$responsive){
                width: auto;
            }
        }
        &__text{
            width: calc(100% - 260px);
            padding: 0 0 0 40px;
            line-height: 1.6;
            @media screen and (max-width:$width){
                padding-left: 32px;   
            }
            @media screen and (max-width:$responsive){
                width: auto;
                padding: 15px 0 0;
            }
            dt{
                font-size: 26px;
                font-weight: 700;
                margin: 0 0 16px;
                padding: 0 0 16px;
                border-bottom: $border 1px solid;
                @media screen and (max-width:$width){
                    font-size: 20px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 17px;   
                }
            }
            dd{
                font-size: 20px;
                @media screen and (max-width:$width){
                    font-size: 14px;   
                }
                strong{
                    color: $eng;
                    font-weight: 700;
                }
            }
        }
    }
    .site-index{
        .index-about{
            background: none;
            &:before,
            &:after{
                display: none;
            }
        }
    }
    .flow{
        &__item{
            @include flex();
            align-items: center;
            background: $glay;
            padding: 20px;
            position: relative;
            margin: 0 0 40px;
            @media screen and (max-width:$width){
                padding: 15px;   
            }
            &:after{
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 20px 20px 0 20px;
                border-color: $glay transparent transparent transparent;
                position: absolute;
                bottom: -20px;
                left: 50%;
                transform: translateX(-50%);
            }
            &:last-child{
                margin: 0;
                &:after{
                    display: none;
                }
            }
            &:nth-child(2n+2){
                > figure{
                    order: 2;
                    @media screen and (max-width:$responsive){
                        order: 0;
                    }
                }
                > dl{
                    padding-left: 20px;
                    @media screen and (max-width:$responsive){
                        padding-left: 0;
                    }
                }
                &:before{
                    left: 0;
                    right: auto;
                    @media screen and (max-width:$responsive){
                        left: auto;
                        right: 0;
                    }
                }
            }
            @for $i from 1 through 10{
                &:nth-child(#{$i}){
                    &:before{
                        content: 'STEP.#{$i}';
                        font-size: 20px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: $eng;
                        color: #FFF;
                        font-weight: 700;
                        width: 120px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        @media screen and (max-width:$width){
                            font-size: 16px;
                            height: 32px;
                            line-height: 32px;
                            width: 100px;
                        }
                    }
                    dt{
                        &:before{
                            content: '#{$i}.';
                        }
                    }
                }
            }
            &__pics{
                width: calc(40% - 20px);
                @media screen and (max-width:$width){
                    width: 280px;   
                }
                @media screen and (max-width:$responsive){
                    width: auto;   
                }
                img{
                    width: 100%;
                }
            }
            &__descript{
                width: calc(60% - 20px);
                @media screen and (max-width:$width){
                    width: calc(100% - 280px);
                    padding: 24px 0 0 32px;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding: 15px 0;
                }
                dt{
                    font-size: 30px;
                    font-weight: 700;
                    @media screen and (max-width:$width){
                        font-size: 22px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 17px;
                    }
                }
                dd{
                    font-size: 16px;
                    margin: 12px  0 0;
                    padding: 12px 0 0;
                    border-top: #FFF 3px solid;
                    @media screen and (max-width:$width){
                        font-size: 13px;   
                    }
                }
            }
        }
    }
}