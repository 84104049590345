.site-index{
    .firstview{
        width: 100%;
        height: calc(100vh - 50px);
        min-height: 580px;
        position: relative;
        @media screen and (max-width:$responsive){
            height: 290px;
            min-height: 1px;
        }
        /*
        &:before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url("../../../images/demo/fv.jpg") no-repeat center;
            background-size: cover;
            z-index: -1;
        }
        */
        video{
            position: absolute;
            top: 0;
            left: 0;
            object-position: center;
            -o-object-fit: cover;
            object-fit: cover;
            width: 100%;
            height: calc(100% - 120px);
            z-index: -1;
        }
        .title{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 5;
            overflow: hidden;
            &.active{
                &:before{
                    transform: scale(1);
                    opacity: 1;
                }
                > *{
                    opacity: 1;
                }
            }
            &:before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 8;
                background: url("../../../images/index/fv_title.jpg") no-repeat center;
                background-size: cover;
                transform: scale(1.1);
                opacity: 0;
                @include transition(1200ms);
            }
            &__message{
                position: absolute;
                top: calc(50% - 80px);
                left: 50%;
                width: 80%;
                max-width: 706px;
                transform: translateX(-50%) translateY(-50%);
                z-index: 10;
                opacity: 0;
                @include transition(800ms,$delay:1200ms);
                @media screen and (max-width:$responsive){
                    top: calc(50% - 24px);
                    width: 88%;
                }
                img{
                    width: 100%;
                }
            }
        }
        .tube{
            width: 40px;
            height: 40px;
            background: $eng;
            color: #FFF;
            border-radius: 50%;
            @include flex();
            align-items: center;
            justify-content: center;
            font-size: 18px;
            position: absolute;
            top: 24px;
            right: 24px;
            @include transition(100ms);
            &:hover{
                transform: scale(1.1);
            }
        }
        .localnav{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 100;
            background: #FFF;
            .widedesiplay{
                padding: 0;
            }
            &__news{
                position: relative;
                background: $eng;
                &__tips{
                    position: absolute;
                    top: -50px;
                    left: 40px;
                    background: #FFF;
                    font-weight: 700;
                    border-radius: 4px;
                    padding: 8px 16px;
                    box-shadow: 0 0 32px rgba(#000,.16);
                    animation: move 1s infinite;
                    @media screen and (max-width:$responsive){
                        left: 15px;
                        top: -40px;
                        padding: 6px 15px;
                    }
                    @keyframes move{
                        0% {
                            transform: translateY(0);
                        }
                        50% {
                            transform: translateY(0);
                        }
                        75% {
                            transform: translateY(-8px);
                        }
                        100% {
                            transform: translateY(0);
                        }
                    }
                    time{
                        color: $eng;
                    }
                    &:after{
                        content: '';
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 16px 10px 0 10px;
                        border-color: #FFF transparent transparent transparent;
                        position: absolute;
                        left: 24px;
                        bottom: -16px;
                    }
                }
                a{
                    @include flex();
                    padding: 16px 0;
                }
                &__cate{
                    display: inline-block;
                    background: $yel;
                    color: #FFF;
                    font-size: 13px;
                    font-weight: 600;
                    padding: 1px 0;
                    text-align: center;
                    width: 96px;
                    i{
                        display: inline-block;
                        margin: 0 8px 0 0;
                    }
                }
                &__title{
                    color: #FFF;
                    font-size: 15px;
                    font-weight: 700;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: calc(100% - 96px);
                    padding: 0 32px 0 16px;
                    @media screen and (max-width:$responsive){
                        font-size: 13px;   
                    }
                    &:before{
                        content: '\f054';
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        position: absolute;
                        font-size: 12px;
                        top: 50%;
                        right: 32px;
                        transform: translateY(-50%);
                    }
                }
            }
            &__nav{
                background: $glay;
                position: relative;
                @media screen and (max-width:$width){
                    padding: 0 15px;   
                }
                @media screen and (max-width:$responsive){
                    display: none;
                }
                &:before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 50%;
                    z-index: 1;
                    background: $eng;
                }
                .widedisplay{
                    @include flex();
                    background: #FFF;
                    z-index: 2;
                    padding: 0;
                    border-radius: 4px;
                    overflow: hidden;
                    box-shadow: 0 0 32px rgba(#000,.1);
                }
                li{
                    list-style: none;
                    width: 16.6%;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 700;
                    position: relative;
                    @media screen and (max-width:$width){
                        font-size: 14px;   
                    }
                    &:before{
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        border-left: darken($border,4%) 2px dotted;
                        width: 2px;
                        height: 50%;
                    }
                    &:first-child:before{
                        display: none;
                    }
                    a{
                        display: block;
                        padding: 20px 0;
                        position: relative;
                        color: $normal;
                        line-height: 1;
                        &:hover{
                            &:after{
                                width: 40px;
                            }
                        }
                        &:after{
                            content: '';
                            width: 0;
                            height: 3px;
                            background: $eng;
                            position: absolute;
                            left: 50%;
                            bottom: 0;
                            transform: translateX(-50%);
                            @include transition(200ms);
                        }
                    }
                }
            }
        }
    }
}