#site-footer{
    background: $normal;
	.box{
		padding: 0 0 64px;
		@media screen and (max-width:$width){
			padding: 0 0 40px;
		}
        .pagetop{
            background: lighten($normal,6%);
            color: rgba(#FFF,.88);
            text-align: center;
            display: block;
            padding: 16px 0;
            margin: 0 0 64px;
			width: 100%;
			@include transition(200ms);
			@media screen and (max-width:$width){
				margin: 0 0 40px;
			}
			&:hover{
				background: lighten($normal,10%);
			}
        }
		.flexer{
			@include flex();
			@media screen and (max-width:$width){
				display: block;
				margin: 0 15px;
			}
            @media screen and (max-width:$responsive){
                margin: 0;   
            }
		}
		.company{
			order: 2;
			width: 40%;
			color: #FFF;
			@media screen and (max-width:$width){
				width: auto;
                margin: 0 0 32px;
			}
			dl{
                position: relative;
				dt{
					font-size: 16px;
					font-weight: 700;
					margin: 0 0 12px;
					padding: 0 0 12px;
					border-bottom: rgba(#FFF,.08) 1px solid;
				}
                dd{
                    &.abs{
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                    .tube{
                        width: 32px;
                        height: 32px;
                        background: #FFF;
                        color: $normal;
                        border-radius: 50%;
                        @include flex();
                        align-items: center;
                        justify-content: center;
                        font-size: 20px;
                    }
                }
			}
		}
		nav{
			width: 56%;
			@media screen and (max-width:$width){
				width: auto;
			}
			ul.fnav{
				@include flex();
				@media screen and (max-width:$width){
					display: block;	
				}
				li{
					list-style: none;
					color: #FFF;
					width: 33.3%;
					@media screen and (max-width:$width){
						width: 100%;
						margin: 0 0 16px;
						border: rgba(#FFF,.16) 1px solid;
						border-radius: 6px;
						&:last-child{
							margin: 0;
						}
					}
					a{
						color: rgba(#FFF,.8);
						&:hover{
							color: #FFF;
							text-decoration: underline;
						}
					}
					dl{
						@media screen and (max-width:$width){
							&.active{
								dt{
									&:after{
										transform: translateY(-50%) rotate(180deg);
									}
								}
								dd{
									display: block;
								}
							}
						}
						dt{
							font-weight: 700;
							margin: 0 0 10px;
							font-size: 16px;
							position: relative;
							@media screen and (max-width:$width){
								font-size: 14px;
								padding: 12px 20px;
								background: rgba(#FFF,.08);
								margin: 0;
                                &:after{
                                    content: "\f078";
                                    font-family: "Font Awesome 5 Free";
                                    font-weight: 900;
                                    font-size: 11px;
                                    position: absolute;
                                    top: 50%;
                                    right: 20px;
                                    transform: translateY(-50%);
                                    opacity: .48;
									@include transition(200ms);
                                }
							}
						}
						dd{
							font-size: 12px;
							margin: 4px 0 0;
							display: block;
							@media screen and (max-width:$width){
								border-bottom: rgba(#FFF,.16) 1px solid;
								a{
									padding: 12px 20px;
									display: block;
									position: relative;
									&:after{
										content: "\f0a9";
										font-family: "Font Awesome 5 Free";
										font-weight: 900;
										font-size: 11px;
										position: absolute;
										top: 50%;
										right: 20px;
										transform: translateY(-50%);
										opacity: .24;
									}
									&:hover{
										text-decoration: none;
									}
								}
								&:last-of-type{
									border: none;
								}
							}
						}
					}
				}
			}
		}
	}
	.copy{
		text-align: center;
		font-size: 13px;
		color: rgba(#FFF,.8);
		display: block;
		border-top: rgba(#FFF,.12) 1px solid;
		padding: 24px 0;
        @media screen and (max-width:$responsive){
            font-size: 10px;   
        }
    }
}


#addmap{
    position: relative;
    height: 400px;
    background: #EEE;
    @media screen and (max-width:$responsive){
        height: 200px;
    }
    iframe{
        vertical-align: bottom;
        filter: grayscale(1);
        height: 400px;
        @media screen and (max-width:$responsive){
            height: 200px;
        }
    }
}

