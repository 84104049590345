$switch: 1200px;

#site-header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: #FFF;
    height: $pc-head;
    @include flex();
    align-items: center;
    @include transition(400ms);
    &.active{
        box-shadow: 0 0 32px rgba(#000,.12);
    }
    @media screen and (max-width:$responsive){
        position: absolute;
        height: $sp-head;
        display: block;
        padding: 15px 0;
    }
    .meta{
        @include flex();
        align-items: center;
        position: relative;
        @media screen and (max-width:$responsive){
            display: block;
        }
        &__logo{
            width: 340px;
            margin: 0 0 0 40px;
            @media screen and (max-width:$width){
                margin: 0 0 0 15px;
                width: 280px;
            }
            @media screen and (max-width:$responsive){
                width: auto;
                margin: 0 auto 15px;
                padding: 0 15px 15px;
                border-bottom: $border 1px solid;
                img{
                    width: 220px !important;
                }
            }
            img{
                width: 100%;
            }
        }
        &__contact{
            @include flex();
            align-items: center;
            position: relative;
            z-index: 2;
            @media screen and (max-width:$responsive){
                padding: 0 15px;
            }
            li{
                list-style: none;
            }
            &__tel{
                margin: 0 32px 0 0;
                @media screen and (max-width:$width){
                    margin: 0 15px 0 0;   
                }
                a{
                    color: $normal;
                    line-height: 1;
                    display: block;
                    font-weight: 700;
                }
                a{
                    font-size: 32px;
                    letter-spacing: .04em;
                    @media screen and (max-width:$width){
                        font-size: 24px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 25px;   
                    }
                    i{
                        display: inline-block;
                        margin: 0 8px 0 0;
                        color: $eng;
                        transform: rotateY(180deg) rotate(-4deg) translateY(-2px);
                        font-size: 22px;
                        @media screen and (max-width:$width){
                            font-size: 18px;   
                        }
                    }
                }
                dl{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 1;
                    margin: 12px 0 0;
                    text-align: right;
                    @media screen and (max-width:$responsive){
                        font-size: 16px;   
                    }
                    dt{
                        margin: 0 0 6px;
                    }
                    dd{
                        font-size: 14.5px;
                        @media screen and (max-width:$responsive){
                            text-align: left;
                        }
                    }
                }
            }
            &__mail{
                a{
                    color: #FFF;
                    display: block;
                    font-weight: 700;
                    background: $eng;
                    height: $pc-head;
                    width: $pc-head;
                    @include flex();
                    align-items: center;
                    justify-content: center;
                    font-size: 28px;
                    padding: 22px 0;
                    @include transition(100ms);
                    @media screen and (max-width:$responsive){
                        height: 72px;
                        width: 72px;
                        border-radius: 6px;
                        font-size: 22px;
                        padding: 12px 0;
                    }
                    &:hover{
                        background: lighten($eng,8%);
                    }
                    i{
                        width: 100%;
                        display: block;
                        text-align: center;
                    }
                    .ttl{
                        font-size: 14px;
                        width: 100%;
                        display: block;
                        text-align: center;
                        @media screen and (max-width:$responsive){
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }    
}

.metamenu{
    position: fixed;
    top: calc(#{$pc-head} + 32px);
    right: 32px;
    z-index: 1999;
    padding: 10px 40px;
    font-size: 20px;
    font-weight: 700;
    border-radius: 999px;
    color: #FFF;
    background: $eng;
    @include flex();
    align-items: center;
    cursor: pointer;
    @media screen and (max-width:$width){
        right: 15px;
    }
    @media screen and (max-width:$responsive){
        top: 12px; 
        padding: 0;
        width: 48px;
        height: 48px;
    }
    &:hover{
        .meta__menu__toggle__clone{
            left: 4px !important;
        }
    }
    &__toggle{
        width: 22px;
        height: 16px;
        display: block;
        position: relative;
        margin: 0 14px 0 0;
        @media screen and (max-width:$responsive){
            margin: 0 auto;   
        }
        &__clone,
        &:before,
        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background: #FFF;
            border-radius: 999px;
        }
        &__clone{
            top: 50%;
            transform: translateY(-50%);
            @include transition(100ms);
        }
        &:after{
            top: auto;
            bottom: 0;
        }
    }
    &__text{
        display: block;
        transform: translateY(-1px);
        @media screen and (max-width:$responsive){
            display: none;
        }
    }
}

#site-navi{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000;
    width: 100%;
    height: 100%;
    background: $eng;
    &:before{
        content: '';
        width: 50%;
        height: 100%;
        background: url("../../../images/common/menu_background_2x.jpg") no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        @media screen and (max-width:$responsive){
            width: 100%;
            background: url("../../../images/common/menu_background_2x.jpg") no-repeat center center;
            background-size: cover;
            opacity: .12;
        }
    }
    .close{
        font-size: 20px;
        font-weight: 700;
        @include flex();
        align-items: center;
        cursor: pointer;
        position: absolute;
        z-index: 100;
        top: 20px;
        right: 40px;
        color: #FFF;
        @media screen and (max-width:$width){
            top: 15px;
            right: 15px;
        }
        &:hover{
            .close__toggle{
                &:before,
                &:after{
                    transform: rotate(0deg);
                }
            }
        }
        &__toggle{
            width: 22px;
            height: 16px;
            display: block;
            position: relative;
            margin: 0 14px 0 0;
            &:before,
            &:after{
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 3px;
                background: #FFF;
                border-radius: 999px;
                transform: rotate(45deg);
                @include transition(100ms);
            }
            &:after{
                bottom: 0;
                transform: rotate(-45deg);
            }
        }
        &__text{
            display: block;
        }
    }
    a{
        color: #FFF;
    }
    .harf{
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        @include flex();
        align-items: center;
        justify-content: center;
        overflow: hidden;
        @media screen and (max-width:$responsive){
            width: 100%;
        }
        &:before{
            content: 'MENU';
            color: #000;
            position: absolute;
            bottom: 24px;
            left: 24px;
            white-space: nowrap;
            font-size: 280px;
            font-weight: 800;
            opacity: .08;
            pointer-events: none;
            line-height: 1;
        }
    }
    ul{
        li{
            list-style: none;
            margin: 20px 0;
            font-size: 20px;
            font-weight: 700;
            a{
                position: relative;
                display: inline-block;
                &:hover{
                    &:before{
                        width: 100%;
                    }
                }
                &:before{
                    content: '';
                    width: 0;
                    height: 3px;
                    background: #FFF;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    @include transition(120ms);
                }
            }
        }
    }
}