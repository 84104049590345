.site-contact{
    .telbox{
        background: $glay;
        padding: 24px 40px;
        border-radius: 4px;
        margin: 0 0 64px;
        @media screen and (max-width:$width){
            padding: 15px;
            margin: 0 0 32px;
        }
        &__text{
            
            font-weight: 700;
            text-align: center;
            line-height: 1.6;
            padding: 8px 0 0;
            font-size: 17px;
            @media screen and (max-width:$responsive){
                font-size: 13px;   
            }
            strong{
                color: $eng;
                font-size: 22px;
                @media screen and (max-width:$width){
                    font-size: 20px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 15px;   
                }
            }
        }
        &__data{
            margin: 24px 0 0;
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;   
            }
            li{
                background: #FFF;
                padding: 24px;
                width: 48.8%;
                border-radius: 4px;
                box-shadow: 0 0 24px rgba(#000,.04);
                @media screen and (max-width:$width){
                    padding: 15px;   
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 15px;
                }
                dl{
                    text-align: center;
                    dt{
                        font-size: 16px;
                        font-weight: 700;
                        i{
                            color: $eng;
                            display: inline-block;
                            margin: 0 8px 0 0;
                        }
                    }
                    dd{
                        font-weight: 700;
                        color: $eng;
                        font-size: 40px;
                        line-height: 1;
                        @media screen and (max-width:$width){
                            font-size: 32px;   
                        }
                        @media screen and (max-width:$responsive){
                            font-size: 24px;   
                        }
                    }
                }
            }
        }
    }
    .send{
        margin: 24px 0 0;
        text-align: center;
        @media screen and (max-width:$responsive){
            margin: 16px 0 0;
        }
        input{
            background: $eng;
            font-size: 18px;
            font-weight: 700;
            color: #FFF;
            padding: 15px 0;
            width: 320px;
            display: inline-block;
            position: relative;
            @include transition(100ms);
            margin: 0 auto;
            border: none;
            cursor: pointer;
            @media screen and (max-width:$responsive){
                width: 100%;
                display: block;
                font-size: 17px;
            }
            &:hover{
                transform: scale(1.1);
                box-shadow: 0 0 16px rgba($eng,.32);
            }
        }
    }
}