.site-faq{
    .faqlist{
        li{
            border: $glay 8px solid;
            padding: 20px 24px;
            margin: 0 0 32px;
            position: relative;
            @media screen and (max-width:$width){
                padding: 15px;   
            }
            &:last-child{
                margin: 0;
            }
            @for $i from 1 through 20{
                &:nth-child(#{$i}){
                    &:before{
                        content: '質問.#{$i}';
                        position: absolute;
                        top: -8px;
                        right: -8px;
                        background: $glay;
                        font-weight: 700;
                        padding: 8px 24px;
                        border-radius: 0 0 0 8px;
                        @media screen and (max-width:$responsive){
                            padding: 5px 20px;
                        }
                    }
                    dl{
                        dt,dd{
                            position: relative;
                            padding-left: 52px;
                            @media screen and (max-width:$responsive){
                                padding-left: 48px;
                            }
                            &:before{
                                $size: 32px;
                                width: $size;
                                height: $size;
                                line-height: $size;
                                text-align: center;
                                position: absolute;
                                top: 4px;
                                left: 0;
                                font-size: 20px;
                                font-weight: 700;
                                color: #FFF;
                                background: $yel;
                                @media screen and (max-width:$responsive){
                                    $size: 28px;
                                    width: $size;
                                    height: $size;
                                    line-height: $size;
                                    font-size: 16px;
                                }
                            }
                        }
                        dt{
                            &:before{
                                content: 'Q';
                            }
                        }
                        dd{
                            &:before{
                                content: 'A';
                                background: $eng;
                            }
                        }
                    }
                }
            }
            dl{
                @media screen and (max-width:$responsive){
                    padding-top: 15px;
                }
                dt{
                    font-size: 22px;
                    font-weight: 700;
                    margin: 0 0 16px;
                    padding: 0 0 16px;
                    border-bottom: $border 1px solid;
                    @media screen and (max-width:$width){
                        font-size: 20px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 15px;
                    }
                }
                dd{
                    font-size: 15px;
                    @media screen and (max-width:$width){
                        font-size: 14px;   
                    }
                    @media screen and (max-width:$responsive){
                        font-size: 13px;
                    }
                    strong{
                        color: $eng;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}