.site-case{
    // Extend index
    .index-case{
        background: none;
        .caselist{
            .items{
                width: auto;
                margin: 0 0 32px;
                &:last-child{
                    margin: 0;
                }
                figure{
                    width: 166px;
                    @media screen and (max-width:$responsive){
                        width: auto;   
                    }
                }
                .data{
                    width: calc(100% - 166px);
                    @media screen and (max-width:$responsive){
                        width: auto;   
                    }
                    h3{
                        font-size: 21px;
                        @media screen and (max-width:$responsive){
                            margin: 15px 0 0;
                            font-size: 17px;
                        }
                    }
                }
                .meta{
                    dl{
                        dt{
                        }
                        dd{

                        }
                    }
                }
            }
        }
    }    
}
