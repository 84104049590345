.site-index{
    .index-post{
        position: relative;
        z-index: 2;
        background: $glay;
        &:before{
            content: '';
            width: 100%;
            height: 56%;
            background: $glay;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
        }
        .list{
            @include flex();
            @media screen and (max-width:$width){
                display: block;   
            }
            > li{
                width: 31%;
                list-style: none;
                background: #FFF;
                @media screen and (max-width:$width){
                    width: auto;
                    margin: 0 0 24px;
                    &:last-child{
                        margin: 0;
                    }
                }
                > a{
                    display: block;
                    color: $normal;
                    position: relative;
                    @media screen and (max-width:$width){
                        @include flex();
                        align-items: center;
                    }
                    @media screen and (max-width:$responsive){
                        display: block;   
                    }
                }
                .tips{
                    content: '';
                    background: $eng;
                    color: #FFF;
                    line-height: 1.2;
                    font-weight: 600;
                    $size: 64px;
                    width: $size;
                    height: $size;
                    @include flex();
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    position: absolute;
                    top: -12px;
                    left: -12px;
                    z-index: 2;
                    @media screen and (max-width:$width){
                        top: -4px;
                        left: -4px;
                    }
                    strong{
                        font-weight: 600;
                        letter-spacing: .12em;
                        display: block;
                    }
                    &:before{
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 4px;
                        left: 4px;
                        border: $eng 1px solid;
                        z-index: -1;
                    }
                }
                figure{
                    @media screen and (max-width:$width){
                        width: 320px;
                    }
                    @media screen and (max-width:$responsive){
                        width: 100%;   
                    }
                    img{
                        width: 100%;
                    }
                }
                dl{
                    padding:  20px;
                    @media screen and (max-width:$width){
                        width: calc(100% - 320px);
                        padding: 15px;
                        padding-left: 32px;
                    }
                    @media screen and (max-width:$responsive){
                        width: auto;
                        padding-left: 15px;
                    }
                    dt{
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 1.56;
                        margin: 0 0 12px;
                        @media screen and (max-width:$width){
                            font-size: 16px;   
                        }
                        i{
                            color: $eng;
                            display: inline-block;
                            margin: 0 12px 0 0;
                        }
                    }
                    dd{
                        font-size: 13px;
                        text-align: justify;
                    }
                }
            }
        }
        ul.category{
            padding: 16px 20px;
            padding-left: 42px;
            border-top: $border 1px solid;
            position: relative;
            font-size: 12px;
            &:before{
                content: '\f02c';
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                position: absolute;
                top: 16px;
                left: 20px;
                opacity: .24;
            }
            li{
                float: left;
                a{
                    color: $normal;
                }
                &:after{
                    content: '/';
                    margin: 0 8px;
                    display: inline-block;
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
    
    .index-category{
        position: relative;
        .parents{
            @include flex();
            @media screen and (max-width:$width){
                display: block;   
            }
            &._active{
                > li {
                    opacity: 1;
                    transform: translateY(0);
                    @for $i from 1 through 10{
                        &:nth-child(#{$i}){
                            @include transition(400ms, $delay:$i*200ms);
                        }
                    }
                }
            }
            &__item{
                list-style: none;
                width: calc(50% - 2px);
                padding-bottom: 72px;
                background: $glay;
                opacity: 0;
                transform: translateY(40px);
                @include transition(400ms);
                @media screen and (max-width:$width){
                    width: auto;
                    padding: 15px;
                    margin: 0 0 24px;
                    &:last-child{
                        margin: 0;
                    }
                }
                /*
                &:nth-child(2){
                    background: #FFF;
                    @media screen and (max-width:$width){
                        background: $glay;
                    }
                }
                */
                &__photo{
                    @media screen and (max-width:$width){
                        width: 280px;
                        margin: 0 32px 15px 0;
                        float: left;
                    }
                    @media screen and (max-width:$responsive){
                        width: 100%;   
                        margin: 0 0 15px;
                        float: none;
                    }
                    img{
                        width: 100%;
                    }
                }
                &__data{
                    margin: 24px;
                    @media screen and (max-width:$width){
                        margin: 24px 0;
                    }
                    dt{
                        font-weight: 700;
                        font-size: 22px;
                        text-align: center;
                        line-height: 1.56;
                        margin: 0 0 8px;
                        @media screen and (max-width:$width){
                            text-align: left;   
                        }
                        @media screen and (max-width:$responsive){
                            text-align: center;
                            font-size: 18px;
                        }
                    }
                    dd{
                        text-align: justify;
                        font-size: 14px;
                        margin: 0 8px;
                        @media screen and (max-width:$responsive){
                            font-size: 13px;
                        }
                    }
                }
                &__list{
                    margin: 24px;
                    padding: 20px 24px;
                    background: #FFF;
                    @media screen and (max-width:$width){
                        margin:  0;
                        clear: both;
                        padding: 15px;
                    }
                    &--glay{
                        background: $glay;
                        @media screen and (max-width:$width){
                            background: #FFF;
                        }
                    }
                    > li{
                        list-style: decimal inside;
                        font-weight: 700;
                        margin: 0 0 4px;
                        &:last-child{
                            margin: 0;
                        }
                        a{
                            color: $normal;
                            &:hover{
                                color: $eng;
                                text-decoration: underline;
                            }
                        }
                    }
                }
                .btnarea{
                    position: absolute;
                    left: 24px;
                    bottom: 20px;
                    margin: 0;
                    width: calc(100% - 48px);
                    @media screen and (max-width:$width){
                        position: relative;
                        left: auto;
                        bottom: auto;
                        width: auto;
                        margin: 15px 0 0;
                    }
                    a{
                        width: auto;
                        display: block;
                    }
                }
            }
        }
    }
    
    .index-kussion-sp{
        display: none;
        @media screen and (max-width:$responsive){
            display: block;
            img{
                width: 100%;
                height: auto;
            }
        }
    }
    .index-kussion{
        height: 190vh;
        padding: 10vh 0 0;
        overflow: hidden;
        position: relative;
        z-index: 1;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        @media screen and (max-width:$responsive){
            display: none !important;
        }
        &.mini{
            height: 100vh;
        }
        div.pins{
            text-align: center;
            color: #FFF;
            z-index: 2;
            >p{
                font-family: 'Noto Sans CJK JP','Hiragino Kaku Gothic ProN','HiraKakuProN-W3','Meiryo','Yu Gothic Medium',sans-serif;
                font-size: 44px;
                opacity: 0;
                text-shadow: 0 0 24px rgba(#000,.64);
                transform: translateY(-50%);
                @include transition(400ms);
                @media screen and (max-width:$width){
                    font-size: 40px;
                }
                @media screen and (max-width:$responsive){
                    font-size: 32px;
                    line-height: 1.5;
                    margin: 0 30px;
                }
                &:before{
                    content: attr(data-eng);
                    display: block;
                    font-size: 14px;
                    line-height: 1;
                }
            }
            &._change{
                >p{
                    opacity: 1;
                    @include transition(800ms);
                }
            }
        }
        @mixin bg(){
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        .secbg-1{
            @include bg();
            opacity: 0;
            z-index: 1;
            @include transition(800ms);
            &._active{
                opacity: 1;
                &:before{
                    opacity: 1;
                    @include transition(800ms,$delay:800ms);
                }
            }
            &:before,
            &:after{
                content: '';
                @include bg();
            }
            &:before{
                background: url("../../../images/index/section_bg_02.jpg") no-repeat center fixed;
                background-size: cover;
                opacity: 0;
                @include transition(800ms);
            }
            &:after{
                background: url("../../../images/index/section_bg_01.jpg") no-repeat center fixed;
                background-size: cover;
                opacity: 0;
                @include transition(800ms);
            }
            &._change{
                &:after{
                    opacity: 1;
                    @include transition(400ms);
                }
            }
        }
        .secbg-2{
            &:before{
                background: url("../../../images/index/section_bg_04.jpg") no-repeat center fixed;
                background-size: cover;
            }
            &:after{
                background: url("../../../images/index/section_bg_03.jpg") no-repeat center fixed;
                background-size: cover;
            }
        }
        .secbg-3{
            &:before{
                background: url("../../../images/index/section_bg_06.jpg") no-repeat center fixed;
                background-size: cover;
                @media screen and (max-width:$responsive){
                    background: url("../../../images/index/section_bg_06_sp.jpg") no-repeat center scroll;
                    background-size: cover;
                }
            }
            &:after{
                background: url("../../../images/index/section_bg_05.jpg") no-repeat center fixed;
                background-size: cover;
                @media screen and (max-width:$responsive){
                    background: url("../../../images/index/section_bg_05_sp.jpg") no-repeat center scroll;
                    background-size: cover;  
                }
            }
        }
    }
    
    .index-about{
        position: relative;
        z-index: 2;
        .catch{
            text-align: center;
            margin: 120px 0;
            @media screen and (max-width:$width){
                margin: 40px 0;
            }
            strong{
                color: $eng;
            }
            dt{
                @include min();
                font-size: 28px;
                font-weight: 700;
                margin: 0 0 16px;
                @media screen and (max-width:$width){
                    font-size: 24px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 20px;
                }
            }
            dd{
                font-size: 17px;
                @media screen and (max-width:$width){
                    font-size: 15px;   
                }
                @media screen and (max-width:$responsive){
                    font-size: 13px;
                    text-align: justify;
                    br{
                        display: none;
                    }
                }
            }
        }
        h3.huge{
            margin: 0 0 24px;
            font-weight: 700;
            font-size: 36px;
            text-align: center;
            @media screen and (max-width:$responsive){
                font-size: 24px;
            }
            strong{
                color: $eng;
                font-size: 64px;
                line-height: 1;
                @media screen and (max-width:$responsive){
                    font-size: 48px;
                }
            }
        }
        .threesec{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;   
            }
            > li{
                width: 31%;
                list-style: none;
                @media screen and (max-width:$width){
                    width: 30%;   
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    display: block;
                    margin: 0 0 15px;
                    padding: 0 0 15px;
                    border-bottom: $border 1px solid;
                    &:last-child{
                        border-bottom: none;
                        margin: 0;
                        padding: 0;
                    }
                }
                figure{
                    border-radius: 50%;
                    overflow: hidden;
                    width: 280px;
                    height: 280px;
                    margin: 0 auto 16px;
                    @media screen and (max-width:$width){
                        width: 400px;
                        height: 400px;
                    }
                    @media screen and (max-width:$responsive){
                        width: 260px;
                        height: 260px;
                        margin:  0 auto;
                    }
                    img{
                        width: 100%;
                        filter: grayscale(1);
                    }
                }
                p{
                    padding: 20px 0;
                    text-align: justify;
                    font-size: 15.5px;
                    @media screen and (max-width:$responsive){
                        padding: 15px 0 0;
                        font-size: 13px;
                    }
                }
            }
        }
    }
    
    .index-case{
        position: relative;
        z-index: 2;
        .casefull{
            @include flex();
            &.block{
                display: block;
                .items{
                    width: 100%;
                    a{
                        height: 400px;
                        @media screen and (max-width:$width){
                            height: 320px;
                        }
                    }
                    &:nth-child(-n+2){
                        margin-top: 24px;
                    }
                    &:first-child{
                        margin-top: 0;
                    }
                }
            }
            @media screen and (max-width:$width){
                display: block;   
            }
            .items{
                width: calc(50% - 12px);
                background: #FFF;
                margin: 24px 0 0;
                @media screen and (max-width:$width){
                    width: auto;
                    &:first-child{
                        margin: 0;
                    }
                }
                &:nth-child(-n + 2){
                    margin: 0;
                    @media screen and (max-width:$width){
                        margin: 24px 0 0;   
                    }
                }
                a{
                    display: block;
                    width: 100%;
                    height: 320px;
                    position: relative;
                    overflow: hidden;
                    &:hover{
                        .bg{
                            transform: scale(1.1);
                            @include transition(800ms);
                        }
                        .data{
                            background: rgba($eng,.8);
                        }
                    }
                }
                .bg{
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-size: cover;
                    background-position: center;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    @include transition(400ms);
                }
                .data{
                    width: calc(100% - 30px);
                    position: absolute;
                    left: 15px;
                    bottom: 15px;
                    background: rgba($eng,.96);
                    padding: 15px;
                    color: #FFF;
                    z-index: 2;
                    @include transition(400ms);
                    h3{
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 1.5;
                        margin: 0 0 12px;
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        @media screen and (max-width:$responsive){
                            font-size: 16px;
                            white-space: normal;
                        }
                    }
                }
                .meta{
                    @include flex();
                    justify-content: flex-start;
                    width: 100%;
                    margin: 16px 0 0;
                    @media screen and (max-width:$responsive){
                        display: block;   
                    }
                    dl{
                        @include flex();
                        &:first-of-type{
                            margin: 0 20px 0 0;
                            @media screen and (max-width:$responsive){
                                margin: 0 0 2px;   
                            }
                        }
                        dt{
                            width: 88px;
                            background: $normal;
                            color: #FFF;
                            text-align: center;
                            font-size: 12px;
                            font-weight: 700;
                            @include flex();
                            justify-content: center;
                            align-items: center;
                        }
                        dd{
                            width: calc(100% - 88px);
                            padding: 0 0 0 16px;
                        }
                    }
                }
            }
        }
        
        .caselist{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;
            }
            .items{
                width: calc(50% - 12px);
                background: #FFF;
                margin: 24px 0 0;
                @media screen and (max-width:$responsive){
                    width: auto;   
                }
                a{
                    @include flex();
                    border: $glay 8px solid;
                    padding: 16px;
                    color: $normal;
                    @media screen and (max-width:$responsive){
                        border: none;
                        padding: 0;
                        display: block;
                    }
                }
                figure{
                    width: 200px;
                    @media screen and (max-width:$responsive){
                        width: auto;   
                    }
                    img{
                        width: 100%;
                    }
                }
                .data{
                    width: calc(100% - 200px);
                    padding: 0 0 0 32px;
                    @media screen and (max-width:$responsive){
                        width: auto;
                        padding: 0;
                    }
                    h3{
                        font-weight: 700;
                        font-size: 19px;
                        line-height: 1.5;
                        margin: 0 0 12px;
                    }
                    &__btn{
                        display: inline-block;
                        background: $eng;
                        font-weight: 700;
                        font-size: 15px;
                        color: #FFF;
                        padding: 4px 32px;
                        @media screen and (max-width:$responsive){
                            display: block;
                            text-align: center;
                            padding: 6px 0;
                            margin: 15px 0 0;
                        }
                        i{
                            display: inline-block;
                            margin: 0 8px 0 0;
                        }
                    }
                }
                .meta{
                    @include flex();
                    justify-content: flex-start;
                    width: 100%;
                    border-top: $border 1px solid;
                    margin: 16px 0 0;
                    padding: 16px 0 0;
                    @media screen and (max-width:$responsive){
                        display: block;
                        padding: 0;
                        border: none;
                    }
                    dl{
                        @include flex();
                        &:first-of-type{
                            margin: 0 20px 0 0;
                            @media screen and (max-width:$responsive){
                                margin: 0 0 2px;
                            }
                        }
                        dt{
                            width: 88px;
                            background: $normal;
                            color: #FFF;
                            text-align: center;
                            font-size: 12px;
                            font-weight: 700;
                            @include flex();
                            justify-content: center;
                            align-items: center;
                        }
                        dd{
                            width: calc(100% - 88px);
                            padding: 0 0 0 16px;
                        }
                    }
                }
            }
        }
        .swiper-pagination{
            position: relative;
            bottom: auto;
            top: 0;
            margin: 16px 0 0;
            .swiper-pagination-bullet{
                width: 10px;
                height: 10px;
                margin: 0 6px;
                opacity: .12;
                &.swiper-pagination-bullet-active{
                    background: $eng;
                    opacity: 1;
                }
            }
        }
    }
    
    .index-ceo{
        position: relative;
        .ceolist{
            @include flex();
            align-items: center;
            background: #FFF;
            padding: 32px;
            @media screen and (max-width:$width){
                padding: 15px;   
            }
            @media screen and (max-width:$responsive){
                display: block;   
            }
            &__pic{
                width: 500px;
                @media screen and (max-width:$width){
                    width: 280px;   
                }
                @media screen and (max-width:$responsive){
                    width: auto;   
                }
                img{
                    width: 100%;
                }
            }
            &__data{
                width: calc(100% - 500px);
                font-size: 15px;
                padding: 0 0 0 48px;
                @media screen and (max-width:$width){
                    width: calc(100% - 280px);
                    padding: 0 0 0 32px;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding: 15px 0 0;
                }
                &__profile{
                    margin: 0 0 16px;
                    padding: 0 0 20px;
                    border-bottom: $border 1px solid;
                    dd{
                        @include min();
                        margin: 0 0 8px;
                    }
                    dt{
                        @include min();
                        font-weight: 700;
                        font-size: 42px;
                        letter-spacing: .08em;
                        line-height: 1;
                        @media screen and (max-width:$width){
                            font-size: 30px;   
                        }
                    }
                }
                &__str{
                    font-size: 20px;
                    font-weight: 700;
                    @media screen and (max-width:$width){
                        font-size: 18px;    
                    }
                }
                &__txt{
                    font-size: 16px;
                    @media screen and (max-width:$width){
                        font-size: 13px;   
                    }
                }
                .btnarea{
                    text-align: left;
                    margin: 24px 0 0;
                    a{
                        text-align: center;
                        margin: 0;
                    }
                }
            }
        }
        .ceomessage{
            background: #FFF;
            padding: 80px 0;
            border-bottom: $glay 8px solid;
            font-size: 20px;
            @media screen and (max-width:$width){
                padding: 32px 15px;   
            }
            @media screen and (max-width:$responsive){
                font-size: 14px;   
            }
            dt{
                @include min();
                font-size: 28px;
                font-weight: 700;
                text-align: center;
                margin: 0 0 24px;
                @media screen and (max-width:$responsive){
                    font-size: 18px;
                    line-height: 1.5;
                }
            }
            dd{
                width: 540px;
                line-height: 2;
                margin: 0 auto 32px;
                text-align: justify;
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 15px;
                    line-height: 1.8;
                }
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
    }
    
    .index-message{
        position: relative;
        z-index: 2;
        background: #FFF;
        .logo{
            text-align: center;
            padding: 160px 0 24px;
            @media screen and (max-width:$width){
                padding: 40px 0 16px;
            }
            img{
                width: 320px;
                @media screen and (max-width:$width){
                    width: 200px;    
                }
            }
        }
        .mes{
            text-align: center;
            padding: 0 0 160px;
            @media screen and (max-width:$width){
                padding: 0 0 40px;
            }
            dt{
                img{
                    @media screen and (max-width:$width){
                        width: 140px;
                    }
                }
            }
            dd{
                margin: 20px 0 0;
            }
            img{
                max-width: 100%;
                @media screen and (max-width:$width){
                    max-width: 80%;   
                }
            }
            .p2x{
                width: 220px;
                margin: 40px auto 0;
                padding: 40px 0 0;
                position: relative;
                @media screen and (max-width:$width){
                    width: 200px;
                    margin: 24px auto 0;
                    padding: 24px 0 0;
                }
                &:before{
                    content: '';
                    width: 64px;
                    height: 3px;
                    background: $normal;
                    opacity: .12;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
                img{
                    width: 100%;
                }
            }
        }
    }
    
	.index-news{
        position: relative;
        z-index: 2;
		background: $normal;
		color: #FFF;
		.display{
			&.flex{
				@include flex();
				@media screen and (max-width:$width){
					display: block;	
				}
			}
		}
		.newsbox{
			width: 100%;
			@include flex();
			@media screen and (max-width:$width){
				width: 100%;
				padding: 0;
				display: block;	
			}
		}
        .leftside{
            width: 280px;
			@media screen and (max-width:$width){
				width: 100%;
			}
			h3{
				line-height: 1;
				font-size: 14px;
				margin: 0 0 16px;
				font-weight: 700;
				@media screen and (max-width:$width){
					font-size: 12px;	
				}
				&:before{
					content: attr(data-eng);
					font-size: 36px;
					display: inline-block;
					margin: 0 24px 0 0;
					@media screen and (max-width:$width){
						font-size: 20px;	
					}
				}
			}
			a{
				display: inline-block;
				font-weight: 700;
				color: #FFF;
				font-size: 15px;
				@media screen and (max-width:$width){
					position: absolute;
					top: 0;
					right: 15px;
					font-size: 13px;
				}
				&:hover{
					text-decoration: underline;
				}
				i{
					display: inline-block;
					font-size: 13px;
					margin: 0 0 0 12px;
				}
			}
        }
        ol{
            width: calc(100% - 280px);
            height: 130px;
            padding: 0 40px 0 0;
            overflow: auto;
			@media screen and (max-width:$width){
				width: 100%;
				height: 164px;
				padding: 15px;
				border: rgba(#FFF,.2) 1px solid;
				border-radius: 4px;
			}
            li{
                border-bottom: rgba(#FFF,.2) 1px solid;
                padding: 0 0 24px;
                margin: 0 0 24px;
				@media screen and (max-width:$width){
					margin: 0 0 15px;
					padding: 0 0 15px;
				}
                &:last-child{
                    margin: 0;
                }
                a{
                    @include flex();
                    align-items: center;
                    color: #FFF;;
					@media screen and (max-width:$width){
						display: block;
					}
                    &:hover{
                        h4{
                            text-decoration: underline;
                        }
                    }
                }
                time,.category{
                    display: block;
                    font-weight: 600;
					@media screen and (max-width:$width){
						float: left;
					}
                }
                time{
                    width: 14%;
                    font-size: 12px;
                    background: rgba(#000,.16);
                    color: #FFF;
                    padding: 2px 0;
                    text-align: center;
					@media screen and (max-width:$width){
						width: 80px;
						font-size: 10px;
						padding: 1px 12px;
						margin: 2px 0 0;
                        background: lighten($normal,8%);
					}
                }
                h4{
                    width: 86%;
                    font-size: 16px;
                    padding: 0 0 0 32px;
					@media screen and (max-width:$width){
						width: auto;
						font-size: 12px;
						padding: 8px 0 0;
						clear: both;
					}
                }
            }
        }
    }
}

#intro{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    @include flex();
    align-items: center;
    justify-content: center;
    color: #FFF;
    background: #FFF url("../../../images/index/intro.png") no-repeat center;
    background-size: 460px;
    @include transition(1200ms);
    @media screen and (max-width:$responsive){
        background: #FFF url("../../../images/index/intro.png") no-repeat center;
        background-size: 240px;
    }
    &.active{
        opacity: 0;
    }
    #bar{
        position: absolute;
        top: 0;
        left: 0;
        height: 5px;
        background: $eng;
    }
}