.site-single{
    .articletitle{
        border-top: $eng 6px solid;
        background: $glay;
        padding: 20px 24px;
        margin: 0 0 32px;
        @media screen and (max-width:$responsive){
            padding: 15px;
            margin: 0 0 24px;
        }
        h2{
            line-height: 1.56;
            font-size: 24px;
            font-weight: 700;
            margin: 0 0 20px;
            @media screen and (max-width:$responsive){
                font-size: 18px;   
            }
        }
        time.date{
            font-weight: 700;
        }
        .meta{
            @include flex();
            justify-content: flex-start;
            width: 100%;
            border-top: $border 1px solid;
            margin: 20px 0 0;
            padding: 20px 0 0;
            @media screen and (max-width:$responsive){
                margin: 15px 0 0;
                padding: 15px 0 0;
            }
            dl{
                @include flex();
                &:first-of-type{
                    margin: 0 20px 0 0;
                    @media screen and (max-width:$responsive){
                        margin: 0 0 2px;
                    }
                }
                dt{
                    width: 88px;
                    background: $normal;
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 700;
                    @include flex();
                    justify-content: center;
                    align-items: center;
                }
                dd{
                    width: calc(100% - 88px);
                    padding: 0 0 0 16px;
                    a{
                        color: $normal;
                        text-decoration: underline;
                        &:hover{
                            color: $eng;
                        }
                    }
                }
            }
        }
    }
    
    .avatars{
        margin: 0 0 32px;
        border: $border 1px solid;
        position: relative;
        border-radius: 4px;
        h3{
            font-size: 15px;
            font-weight: 700;
            position: absolute;
            top: 0;
            right: 24px;
            z-index: 2;
            background: $glay;
            border: $border 1px solid;
            border-radius: 0 0 6px 6px;
            border-top: none;
            padding: 4px 16px;
            @media screen and (max-width:$responsive){
                right: 15px;
                font-size: 13px;
            }
            i{
                color: $eng;
                display: inline-block;
                margin: 0 8px 0 0;
            }
        }
        &__box{
            padding: 24px;
            @include flex();
            align-items: center;
            @media screen and (max-width:$responsive){
                padding: 15px;
                padding-top: 40px;
            }
        }
        &__icon{
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            @media screen and (max-width:$responsive){
                width: 64px;
                height: 64px;
            }
            img{
                width: 100%;
            }
        }
        &__txt{
            width: calc(100% - 80px);
            padding: 0 0 0 24px;
            @media screen and (max-width:$responsive){
                width: calc(100% - 64px);   
            }
            dt{
                font-size: 18px;
                font-weight: 700;
                @media screen and (max-width:$responsive){
                    font-size: 16px;   
                }
            }
            dd{
                font-size: 15px;
                line-height: 1.66;
                @media screen and (max-width:$responsive){
                    font-size: 13px;   
                }
            }
        }
    }
    .mb-px{
        margin-bottom: 32px;
        .telbox{
            margin: 0;
        }
    }
    .casebnr{
        margin: 24px 0;
        text-align: center;
        img{
            max-width: 100%;
        }
    }
    .contactbtn{
        margin: 20px 0 0;
        a{
            background: $eng;
            display: block;
            color: #FFF;
            border-radius: 4px;
            text-align: center;
            font-size: 22px;
            font-weight: 700;
            padding: 16px 0;
            box-shadow: 0 4px 0 rgba(darken($eng,8%),1);
            @media screen and (max-width:$responsive){
                font-size: 15px;   
            }
            &:hover{
                transform: translateY(3px);
                box-shadow: 0 1px 0 rgba(darken($eng,8%),1);
            }
            i{
                display: inline-block;
                margin: 0 8px 0 0;
            }
        }
    }
    
    .articlenavigation{
        &__two{
            @include flex();
            li{
                list-style: none;
                width: calc(50% - 12px);
                text-align: center;
                @media screen and (max-width:$responsive){
                    width: calc(50% - 8px);    
                }
                &:nth-child(2){
                    a{
                        i{
                            left: auto;
                            right: 24px;
                            @media screen and (max-width:$responsive){
                                right: 15px;   
                            }
                        }
                    }
                }
                &:last-child{
                    width: 100%;
                    margin: 16px 0 0;
                    a{
                        background: $normal;
                        border-color: $normal;
                        color: #FFF;
                        &:hover{
                            background: lighten($normal,8%); 
                            border-color: lighten($normal,8%);
                        }
                    }
                }
                a{
                    position: relative;
                    display: block;
                    background: $glay;
                    border: $glay 2px solid;;
                    border-radius: 4px;
                    padding: 12px 15px;
                    font-size: 17px;
                    color: $normal;
                    @media screen and (max-width:$responsive){
                        font-size: 14px;
                    }
                    &:hover{
                        border: $border 2px solid;;
                        background: #FFF;
                    }
                    i{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 24px;
                        opacity: .24;
                        @media screen and (max-width:$responsive){
                            left: 15px;
                        }
                    }
                }
            }
        }
    }
}
