@charset "utf-8";

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#1c2023;
$eng: #a92233;
$yel: #d5b107;
$link:$eng;
$border:#e8e8e8;
$glay:#f4f4f4;
$bage:#f6f1e8;


/* SITE SETTING
------------------------------------------------------------*/
$width:1120px;
$pc-head:108px;
$sp-head:175px;

$responsive: 768px;

@media screen and (min-width:$responsive + 1px){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width:$width){
	@include default(
		auto, //サイト幅
		13px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
    .widedisplay{
        padding: 0 15px;
    }
}

@media screen and (max-width:$responsive){
	@include default(
		auto, //サイト幅
		13px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
    .widedisplay{
        padding: 0 15px;
    }
}

@mixin grd(){
    background: rgb(173,49,64);
    background: -moz-linear-gradient(-45deg,  rgba(173,49,64,1) 0%, rgba(148,49,171,1) 100%);
    background: -webkit-linear-gradient(-45deg,  rgba(173,49,64,1) 0%,rgba(148,49,171,1) 100%);
    background: linear-gradient(135deg,  rgba(173,49,64,1) 0%,rgba(148,49,171,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ad3140', endColorstr='#9431ab',GradientType=1 );
}

@import "common/header";
@import "common/footer";
@import "common/bread";
@import "common/sidebar";
@import "page/firstview";
@import "page/index";
@import "page/case";
@import "page/blog";
@import "page/single";
@import "page/message";
@import "page/contact";
@import "page/contact";
@import "page/faq";
@import "page/service";
@import "page/company";
@import "page/privacy";
@import "page/sitemap";
@import "page/edit";

#site-wrapper,#site-footer{
    position: relative;
    top: $pc-head;
    @media screen and (max-width:768px){
        top: $sp-head;
    }
}

#site-clum{
    @include flex();
    align-items: flex-start;
    #site-container,
    #site-sidebar{
    }
    #site-container{
        width: calc(100% - 280px);
        padding-right: 48px;
        @media screen and (max-width:$width){
            width: 100%;
            padding-right: 0;
        }
    }
    #site-sidebar{
        width: 280px;
        @media screen and (max-width:$width){
            width: 100%;
            padding: 0;
        }
        @media screen and (max-width:$responsive){
            padding: 0 0 40px;     
        }
    }
}


.site-module{
    &.wrap{
        padding: 80px 0;
        @media screen and (max-width:$width){
            padding: 56px 0;   
        }
        @media screen and (max-width:$responsive){
            padding: 32px 0;   
        }
        &.mid{
            padding: 72px 0;
            @media screen and (max-width:$width){
                padding: 48px 0;   
            }
            @media screen and (max-width:$responsive){
                padding: 32px 0;
            }
        }
        &.mid-top{
            padding-top: 72px;
            @media screen and (max-width:$width){
                padding-top: 48px;   
            }
            @media screen and (max-width:$responsive){
                padding-top: 32px;   
            }
        }
        &.mid-bottom{
            padding-bottom: 72px;
            @media screen and (max-width:$width){
                padding-bottom: 48px;   
            }
            @media screen and (max-width:$responsive){
                padding-bottom: 32px;   
            }
        }
        &.no-top{
            padding-top: 0;
        }
        &.no-bottom{
            padding-bottom: 0;
        }
        &.border{
            border-bottom: $glay 8px solid;
        }
        &.bg{
            background: $glay;
        }
        &.dark{
            background: $eng;
            color: #FFF;
        }
    }
    h1,h2,h3,h4,h5,h6{
        &.borders{
            border-bottom: $border 1px solid;
            position: relative;
            padding: 10px 12px;
            padding-left: 20px;
            font-size: 24px;
            line-height: 1;
            font-weight: 700;
            margin: 0 0 24px;
            small{
                display: inline-block;
                font-size: 14px;
                margin: 0 0 0 12px;
            }
            &:before{
                content: '';
                width: 5px;
                height: 70%;
                background: $eng;
                border-radius: 4px;
                display: block;
                position: absolute;
                top: 15%;
                left: 0;
            }
        }
    }
    h1,h2,h3,h4,h5,h6{
        &.bgs{
            background: $glay;
            padding: 5px 16px;
            border-left: $eng 6px solid;
            font-weight: 700;
            font-size: 18px;
        }
    }
    i.scroll{
        $size: 88px;
        position: absolute;
        right: 48px;
        bottom: 0;
        z-index: 2;
        height: $size;
        background: $border;
        @media screen and (max-width:$width){
            display: none;
        }
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background: $eng;
            width: 2px;
            height: 100%;
            animation: strokes 2s infinite ease-in-out;
            @keyframes strokes{
                0%{
                    top: 0;
                    height: 0;
                }
                25%{
                    top: 0;
                    height: 100%;
                }
                50%{
                    top: 0;
                    height: 100%;
                }
                75%{
                    top: 100%;
                    height: 0;
                }
                100%{
                    top: 0;
                    height: 0;
                }
            }
        }
        &:after{
            content: 'SCROLL';
            font-size: 12px;
            -webkit-writing-mode: vertical-rl;
            -moz-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            white-space: nowrap;
            letter-spacing: .24em;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            color: $eng;
            font-weight: 700;
        }
    }
    h2.large{
        line-height: 1;
        font-size: 38px;
        font-weight: 800;
        text-align: center;
        margin: 0 0 32px;
        background: url("../../images/common/h_mark_2x.png") no-repeat center top;
        background-size: 44px 10px;
        padding: 24px 0 0;
        &.rvs{
            color: $eng;
            &:after{
                color: $normal;
            }
        }
        @media screen and (max-width:$width){
            font-size: 32px;   
        }
        @media screen and (max-width:$responsive){
            font-size: 24px;   
            margin: 0 0 24px;
        }
        &:after{
            content: attr(data-eng);
            font-size: 14px;
            letter-spacing: .016em;
            display: block;
            color: $eng;
            margin: 14px 0 0;
            @media screen and (max-width:$responsive){
                margin: 12px 0 0;   
            }
        }
    }
    .btnarea{
        margin: 40px 0 0;
        text-align: center;
        @media screen and (max-width:$responsive){
            margin: 24px 0 0;   
        }
        a{
            background: $eng;
            font-size: 18px;
            font-weight: 700;
            color: #FFF;
            padding: 11px 0;
            width: 320px;
            display: inline-block;
            position: relative;
            @include transition(180ms, $ease:linear);
            @media screen and (max-width:$responsive){
                width: auto;
                display: block;
            }
            &:hover{
                transform: scale(1.1);
                box-shadow: 0 0 16px rgba($eng,.32);
                @media screen and (max-width:$width){
                    transform: scale(1);
                }
            }
            i{
                display: inline-block;
                margin: 0 12px 0 0;
            }
        }
    }
	.table-style{
		width: 100%;
		border-collapse: collapse;
		@media screen and (max-width:$responsive){
			display: block;
			tbody,
			tr,
			th,
			td{
				display: block;
			}
		}
		tbody{
			tr{
                border-bottom: $border 1px solid;
                @media screen and (max-width:$responsive){
                    border: none;
                }
                &:last-child{
                    border: none;
				}
				th,td{
					padding: 32px 0;
					vertical-align: middle;
					text-align: left;
					@media screen and (max-width:$responsive){
						border: none;
                        padding: 12px 0;
					}
					input[type=text],
					input[type=email],
					input[type=tel],
					select,
					option,
					textarea{
						@include font();
						border: $border 1px solid;
						padding: 8px 10px;
						width: 100%;
						font-size: 15px;
						border-radius: 4px;
						overflow: hidden;
						&:focus{
							box-shadow: inset 1px 2px 2px rgba(#000,.16);
						}
					}
                    label{
                        display: inline-block;
                        margin: 0 20px 0 0;
                        input{
                            margin: 0 8px 0 0;
                            display: inline-block;
                            vertical-align: middle;
                            transform: translateY(-1px);
                            cursor: pointer;
                        }
                        &:last-of-type{
                            margin: 0;
                        }
                    }
					select{
						width: auto;
					}
					textarea{
						height: 200px;
					}
				}
				th{
					width: 240px;
					@media screen and (max-width:$responsive){
						width: auto;
                        background: $glay;
                        padding-left: 15px;
                        padding-right: 15px;
					}
					&.hiss{
						&:after{
							content: '必須';
							float: right;
							background: $eng;
							color: #FFF;
							padding: 2px 12px;
							border-radius: 4px;
							font-weight: 700;
							font-size: 12px;
						}
					}
				}
                td{
                    padding-left: 56px;
                    @media screen and (max-width:$responsive){
                        padding-left: 0;   
                    }
                }
			}
		}
    }
}

.scroll-anime{
	opacity: 0;
	transform: translateY(48px);
	@include transition(800ms);
	&._active{
		opacity: 1;
		transform: translateY(0);
	}
}

.scroll-img{
	position: relative;
	$ease-transition: cubic-bezier(0.86, 0, 0.07, 1);
	$speed: 600ms;
	&:before,
	&:after{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
	&:before{
		background: #FFF;
		@include transition($speed , $delay:$speed , $ease:$ease-transition);
	}
	&:after{
		background: #f2f2f2;
		@include transition($speed , $ease: $ease-transition);
	}
	&.__active{
		&:before{
			width: 0;
		}
		&:after{
			width: 0;
		}
	}
}

nav.pager{
    margin: 32px 0 0;
    text-align: center;
    a,
    span{
        $size: 30px;
        width: $size;
        height: $size;
        line-height: $size;
        text-align: center;
        display: inline-block;
        background: $glay;
        color: $normal;
        font-size: 16px;
        margin: 0 4px;
    }
    a{
        &:hover{
            background: $eng;
            color: #FFF;
        }
    }
    span{
        background: $eng;
        color: #FFF;
    }
}

.scale-enter-active, .scale-leave-active {
	transition: 600ms;
}
.scale-enter, .scale-leave-to {
	opacity: 0;
}


