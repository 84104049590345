.site-blog{
    .bloglist{
        li{
            margin: 0 0 24px;
            padding: 0 0 24px;
            border-bottom: $border 1px solid;
            @media screen and (max-width:$responsive){
                margin: 0 0 15px;
                padding: 0 0 15px;
            }
            &:last-child{
                margin: 0;
                padding: 0;
                border: none;
            }
            a{
                display: block;
                color: $normal;
                @include flex();
                @media screen and (max-width:$responsive){
                    display: block;
                }
                &:hover{
                    opacity: .88;
                }
                figure{
                    width: 360px;
                    @media screen and (max-width:$responsive){
                        width: auto;
                    }
                    img{
                        width: 100%;
                    }
                }
                dl{
                    width: calc(100% - 360px);
                    padding: 0 0 0 40px;
                    @media screen and (max-width:$responsive){
                        width: auto;
                        padding: 15px 0 0;
                    }
                    dt{
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 1.6;
                        margin: 12px 0 8px;
                        @media screen and (max-width:$responsive){
                            font-size: 16px;
                        }
                    }
                    dd{
                        &.time{
                            background: $eng;
                            color: #FFF;
                            display: inline-block;
                            padding: 2px 16px;
                            font-size: 13px;
                            @media screen and (max-width:$responsive){
                                font-size: 11px;
                            }
                        }
                        &.descript{
                            font-size: 15px;
                            text-align: justify;
                            @media screen and (max-width:$responsive){
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
}
